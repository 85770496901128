import AddressDTO from "@/dto/AddressDTO";

export default class SaveIT2104RequestDTO {

    public publicId = "";
    public firstName = "";
    public lastName = "";
    public ssn = "";
    public single = false;
    public married = false;
    public higherRate = false;
    public address = new AddressDTO();
    public nycResident: boolean | null = null;
    public yonkersResident: boolean | null = null;
    public allowancesAll: number | null = null;
    public allowancesNyc: number | null = null;
    public nysAmount: number | null = null;
    public nycAmount: number | null = null;
    public yonkersAmount: number | null = null;

    public merge(obj: SaveIT2104RequestDTO) {
        if (obj.firstName) {
            this.firstName = obj.firstName;
        }
        if (obj.lastName) {
            this.lastName = obj.lastName;
        }
        if (obj.ssn) {
            this.ssn = obj.ssn
        }
    }
}