

































































































































































































































import {Component, Vue} from "vue-property-decorator";
import {STATE_LIST} from "@/constants/ProfileConstants";
import {namespace} from "vuex-class";
import SaveIT2104RequestDTO from "@/dto/archive/SaveIT2104RequestDTO";
import DocumentService from "@/services/DocumentService";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import RegexUtils from "@/utils/RegexUtils";
import PortalInput from "@/components/common/PortalInput.vue";
import AutoComplete from "@/components/common/AutoComplete.vue";
import AutoCompleteDTO from "@/components/common/AutoCompleteDTO";
import {InputType} from "@/components/common/InputType";
import {ifValid} from "@/utils/ComponentUtils";
import SaveW4RequestDTO from "@/dto/archive/SaveW4RequestDTO";

const AppModule = namespace("App");

@Component({
	computed: {
		InputType() {
			return InputType
		},
		AutoCompleteDTO() {
			return AutoCompleteDTO
		}
	},
	components: {AutoComplete, PortalInput}
})
export default class FormIt2104 extends Vue {

	private successful = false;

	private message = "";

	private states = STATE_LIST

	@AppModule.State
	private loading!: boolean;

	@AppModule.Action
	private startLoading!: () => void

	@AppModule.Action
	private stopLoading!: () => void

	private exists = true;

	private processed = false;

	private request = new SaveIT2104RequestDTO();


	mounted() {
		this.request.publicId = this.$route.params.publicId;
		this.request.single = true;
		this.request.nycResident = false;
		this.request.yonkersResident = false;
		this.loadForm();
	}

	save() {
		ifValid(this, () => {
			this.message = "";
			this.startLoading();
			DocumentService.fillIt2104(this.request).then(
				success => {
					this.successful = true;
					this.processed = true;
				},
				error => {
					this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
				}
			).then(() => this.stopLoading());
		})
	}

	private loadForm(): void{
		this.startLoading();
		DocumentService.loadPreparedPublicForm<SaveIT2104RequestDTO>(this.$route.params.publicId).then(
			success => {
				this.request.merge(success.data);
				this.stopLoading()
			},
			error => {
				this.exists = false;
				this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string
				this.successful = false;
				this.stopLoading()
			});
	}

	ensureExists() {
		this.startLoading();
		DocumentService.existsByPublicId(this.$route.params.publicId).then(
			success => this.exists = success.data,
			error => this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string
		).then(() => this.stopLoading());
	}

	onSingle() {
		this.request.single = true;
		this.request.married = false;
		this.request.higherRate = false;
	}

	onMarried() {
		this.request.single = false;
		this.request.married = true;
		this.request.higherRate = false;
	}

	onHigherRate() {
		this.request.single = false;
		this.request.married = false;
		this.request.higherRate = true;
	}

	handleSsn(event: KeyboardEvent) {
		let current = this.request.ssn ? this.request.ssn : "";
		if (RegexUtils.testSsnPreSeparator(current)) {
			current = current.concat('-')
			this.request.ssn = current;
		}
		const next = (current).concat(event.key)
		if (!RegexUtils.testSsn(next)) {
			event.preventDefault();
		}
	}

}
